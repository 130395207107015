var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-app",
    [
      _c(
        "v-app-bar",
        {
          attrs: {
            app: "",
            floating: "",
            flat: "",
            color: "transparent"
          }
        },
        [
          _c(
            "v-btn",
            {
              attrs: {
                icon: "",
                dark: ""
              },
              on: {
                click: function click($event) {
                  return _vm.$router.go(-1)
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          )
        ],
        1
      ),
      _c(
        "v-main",
        {
          staticClass: "ma-0 pa-0"
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }