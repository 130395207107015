
























import { Component, Vue } from "vue-property-decorator";
import LoaderReport from "@/components/utility/LoaderReport.vue";

import { ReportModule } from "@/store/modules/report.store";
import { LocalHostToPartnerMapper } from "@/lib/utility/local-host-to-partner-mapper";

@Component({
  components: { LoaderReport }
})
export default class V extends Vue {
  get loading() {
    return ReportModule.loading;
  }

  async mounted() {
    try {
      await LocalHostToPartnerMapper.tryGetPartnerByHost(window.location.hostname);
    } catch (error) {
      this.$log.error(error);
      this.$toast("Fehler beim Laden der Anwendung.");
      this.$router.push({ name: "PartnerContactError" });
    } finally {
      ReportModule.setLoading(false);
    }
  }
}
