import { permissionGuard } from "@/auth/permissionAuthGuard";
import { ReportScreenEnum } from "@/store/enum/partner/report-screen.enum";
import Camera from "@/views/Camera.vue";
import Error from "@/views/Error.vue";
import Home from "@/views/Home.vue";
import PartnerContact from "@/views/PartnerContact.vue";
import PartnerContactError from "@/views/PartnerContactError.vue";
import PartnerContactSuccess from "@/views/PartnerContactSuccess.vue";
import PartnerLogin from "@/views/PartnerLogin.vue";
import ReportDatePicker from "@/views/report/ReportFormDatePicker.vue";
import ReportDescription from "@/views/report/ReportFormDescription.vue";
import ReportPersonalData from "@/views/report/ReportFormPersonalData.vue";
import ReportPersonalDataSimple from "@/views/report/ReportFormPersonalDataSimple.vue";
import ReportCockpitImage from "@/views/report/ReportImageCockpit.vue";
import ReportDamageImage from "@/views/report/ReportImageDamage.vue";
import ReportDamageDetailImage from "@/views/report/ReportImageDamageDetail.vue";
import ReportOverviewImage from "@/views/report/ReportImageOverview.vue";
import ReportRegistrationImage from "@/views/report/ReportImageRegistration.vue";
import ReportSuccess from "@/views/report/ReportSuccess.vue";
import ReportSummary from "@/views/report/ReportSummary.vue";
import ReportHome from "@/views/ReportHome.vue";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { routes as docphantRoutes } from "./docphant.router";
import { getLoginRoutes } from "./login.router";
import { SettingRoutes } from "./settings.router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/" + ReportScreenEnum.welcome,
    component: ReportHome,
    name: ReportScreenEnum.welcome
  },
  {
    path: "/" + ReportScreenEnum.registration,
    component: ReportRegistrationImage,
    name: ReportScreenEnum.registration
  },
  {
    path: "/" + ReportScreenEnum.damagelocator,
    component: () => import(/* webpackChunkName: "svg" */ "@/views/report/ReportLocation.vue")
  },
  {
    path: "/" + ReportScreenEnum.overviewimage,
    component: ReportOverviewImage
  },
  {
    path: "/" + ReportScreenEnum.componentimage,
    component: ReportDamageImage
  },
  {
    path: "/" + ReportScreenEnum.damagedetail,
    component: ReportDamageDetailImage
  },
  {
    path: "/" + ReportScreenEnum.mileage,
    component: ReportCockpitImage
  },
  {
    path: "/" + ReportScreenEnum.message,
    component: ReportDescription
  },
  {
    path: "/" + ReportScreenEnum.contactdetails,
    component: ReportPersonalData
  },
  {
    path: "/" + ReportScreenEnum.simplecontact,
    component: ReportPersonalDataSimple
  },
  {
    path: "/" + ReportScreenEnum.daterequest,
    component: ReportDatePicker
  },
  {
    path: "/" + ReportScreenEnum.closing,
    component: ReportSummary
  },
  {
    path: "/success",
    component: ReportSuccess,
    name: "ReportSuccess"
  },
  {
    path: "/camera",
    name: "Camera",
    component: Camera
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ "../views/About.vue")
  },
  {
    path: "*",
    component: Home
  },
  {
    path: "/partner/:partnerName/contact",
    name: "PartnerContact",
    component: PartnerContact
  },
  {
    path: "/partner/contact/success",
    name: "PartnerContactSuccess",
    component: PartnerContactSuccess
  },
  {
    path: "/partner/contact/error",
    name: "PartnerContactError",
    component: PartnerContactError
  },
  {
    path: "/partner/:partnerName/report",
    name: "ReportHome",
    component: ReportHome
  },
  {
    path: "/errors",
    name: "Error",
    component: Error
  }
];

docphantRoutes.forEach(route => {
  route.path = "/docphant" + route.path;
  routes.push(route);
});

getLoginRoutes(PartnerLogin).forEach((route: RouteConfig) => {
  route.path = "/login" + route.path;
  routes.push(route);
});

SettingRoutes.forEach((route: RouteConfig) => {
  route.path = "/settings" + route.path;
  routes.push(route);
});

const router = new VueRouter({
  routes
});

router.beforeEach(permissionGuard);

export default router;
