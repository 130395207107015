







import { Component, Vue } from "vue-property-decorator";

import LayoutTransparent from "@/layouts/LayoutTransparent.vue";

import CameraApp from "@/components/camera/CameraApp.vue";

@Component({
  components: {
    LayoutTransparent,
    CameraApp
  }
})
export default class Camera extends Vue {}
