





import { Component, Vue } from "vue-property-decorator";
import ReportHome from "@/views/ReportHome.vue";

@Component({
  components: {
    ReportHome
  }
})
export default class Home extends Vue {}
